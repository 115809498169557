import { Dictionary, Typology } from "../interfaces";

import { BrandCode } from "./../interfaces/index";
import { CategoryAll } from "../reducers/categories";
import { FiltersState } from "../reducers/filters";
import { History } from "history";
import { mainDomain } from "./domain";
import moment from "moment";
import queryString from "query-string";

export const detectIE11 = () => {
  var ua = window.navigator.userAgent;

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11, return version number
    return true;
  } else {
    return false;
  }
};

export const disableTreeNode = (node: Dictionary<any>, ids: string[]) => {
  const { key, children } = node;
  // controllo se il nodo è presente nella lista dei filtri attivi
  const isNodeDisabled = ids.indexOf(key) === -1;
  // se il nodo ha figli cerco tra i figli
  if (children !== undefined && children.length > 0) {
    let foundAtLeastOneChildredEnabled = false;
    const _children = children.map((_node: Dictionary<any>) => {
      const treeNode = disableTreeNode(_node, ids);
      // se trovo almeno un children abilitato non devo disabilitare il padre
      if (!foundAtLeastOneChildredEnabled)
        foundAtLeastOneChildredEnabled = ids.indexOf(_node.key) !== -1;
      return treeNode;
    });
    const _node = {
      ...node,
      children: _children,
      disabled: foundAtLeastOneChildredEnabled ? false : isNodeDisabled
    };
    return _node;
  } else {
    const newNode = {
      ...node,
      disabled: isNodeDisabled
    };
    return newNode;
  }
};

export const disableMainTreeNode = (node: Dictionary<any>, ids: string[]) => {
  const { key, children } = node;
  // controllo se il nodo è presente nella lista dei filtri attivi
  const isNodeDisabled = ids.indexOf(key) === -1;
  // se il nodo ha figli cerco tra i figli
  if (children !== undefined && children.length > 0) {
    let foundAtLeastOneChildredEnabled = false;
    const _children = children.map((_node: Dictionary<any>) => {
      const treeNode = disableTreeNode(_node, ids);
      // se trovo almeno un children abilitato non devo disabilitare il padre
      if (!foundAtLeastOneChildredEnabled)
        foundAtLeastOneChildredEnabled = !treeNode.disabled;
      return treeNode;
    });
    const _node = {
      ...node,
      children: _children,
      isDisabled: foundAtLeastOneChildredEnabled ? false : isNodeDisabled
    };
    return _node;
  } else {
    const newNode = {
      ...node,
      isDisabled: isNodeDisabled
    };
    return newNode;
  }
};

export const disableFlatNode = (node: Dictionary<any>, ids: string[]) => {
  const { id } = node;
  return {
    ...node,
    disabled: ids.indexOf(id) === -1
  };
};

export const getChildrenNod = (treeNode: Dictionary<any>) => {
  const { node, children } = treeNode;
  let _keys: any[] = [];
  if (children !== undefined) {
    children.forEach((element: any) => {
      _keys = [..._keys, ...getChildrenNod(element)];
    });
  }

  let keys = node ? [node.key, ..._keys] : _keys;
  return keys;
};

export const getChildrenNode = (
  ids: string[],
  categoriesById: Dictionary<CategoryAll>
) => {
  let keys: string[] = [];
  ids.forEach((id) => {
    if (categoriesById) {
      const node = categoriesById[id];
      let key = node.key;
      if (node.children !== undefined) {
        keys = [...getChildrenNode(node.children, categoriesById), ...keys];
      }
      keys = [key, ...keys];
    }
  });

  return keys;
};

export const formatDate = (date: string): string => {
  const _date = moment(date);
  return _date.format("DD/MM/YYYY");
};

export const getLanguageIcon = (id: string): string => {
  // const code = id.substr(3, 2);
  const code = id === "en" ? "GB" : id.toUpperCase();
  return code;
};

export const fileScr = async (file: Blob): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
  });
};

export const openForums = (data: {
  accessToken: string;
  brandCode: string;
  brandId: string;
  languageId: string;
  userName: string;
  destination: "forum" | "user";
  topicId?: string;
  topicUrl?: string;
}) => {
  const {
    accessToken,
    brandCode,
    brandId,
    languageId,
    userName,
    topicId,
    topicUrl,
    destination
  } = data;
  const form = document.createElement("form");
  form.setAttribute("method", "post");

  let actionUrl = `${mainDomain}asm/forum/login`;
  /*let actionUrl = `${mainDomain}asm/user`;
  if (brandCode === BrandCode.CASEIH) {
    actionUrl = `${mainDomain}caseih/forum/login`;
  }*/
  form.setAttribute("action", actionUrl);

  // form.setAttribute("target", "_blank");

  var accessTokenField = document.createElement("input");
  accessTokenField.setAttribute("type", "hidden");
  accessTokenField.setAttribute("name", "access_token");
  accessTokenField.setAttribute("value", accessToken as string);

  const client_id = process.env.REACT_APP_CLIENT_ID;
  var clientIdField = document.createElement("input");
  clientIdField.setAttribute("type", "hidden");
  clientIdField.setAttribute("name", "client_id");
  clientIdField.setAttribute("value", client_id as string);

  var userField = document.createElement("input");
  userField.setAttribute("type", "hidden");
  userField.setAttribute("name", "username");
  userField.setAttribute("value", userName as string);

  var brandField = document.createElement("input");
  brandField.setAttribute("type", "hidden");
  brandField.setAttribute("name", "brand");
  brandField.setAttribute("value", brandId as string);

  var languageField = document.createElement("input");
  languageField.setAttribute("type", "hidden");
  languageField.setAttribute("name", "lang");
  languageField.setAttribute("value", languageId as string);

  var destinationField = document.createElement("input");
  destinationField.setAttribute("type", "hidden");
  destinationField.setAttribute("name", "destination");
  destinationField.setAttribute("value", destination);

  if (topicId && topicUrl) {
    var topicIdField = document.createElement("input");
    topicIdField.setAttribute("type", "hidden");
    topicIdField.setAttribute("name", "topicId");
    topicIdField.setAttribute("value", topicId as string);

    var topicUrldField = document.createElement("input");
    topicUrldField.setAttribute("type", "hidden");
    topicUrldField.setAttribute("name", "topicUrl");
    topicUrldField.setAttribute("value", topicUrl as string);

    form.appendChild(topicIdField);
    form.appendChild(topicUrldField);
  }

  form.appendChild(accessTokenField);
  form.appendChild(clientIdField);
  form.appendChild(userField);
  form.appendChild(brandField);
  form.appendChild(languageField);
  form.appendChild(destinationField);
  document.body.appendChild(form);
  
  form.submit();
  document.body.removeChild(form);
};

export function getBreadcrumbItems(baseType: any, id: string) {
  let items = new Map();
  if (!baseType) return Array.from(items || []);
  const traverse = (node: any, path: Map<string, any>, id: string) => {
    if (!path) path = new Map();
    if (items.has(id)) return;
    if (node.id) path.set(node.id, node);
    items = new Map(path);
    if (node.id == id) return;
    if (node.subtypes.length > 0) {
      node.subtypes.forEach((item: any) => traverse(item, new Map(path), id));
    }
  };
  traverse(baseType, new Map(), id);
  if(!items) return [];
  return Array.from(items.values());
}

export const hasKeys = (obj: any, keys: any[]) => {
  return (
    keys.length > 0 &&
    keys.every((key) => {
      if (typeof obj !== 'object' || !obj.hasOwnProperty(key)) return false;
      obj = obj[key];
      return true;
    })
  );
};


export function findSubtypeById(parent: any, subtypeId: string | number) {
  if (!parent) {
    return null;
  }
  if (parent.id == subtypeId) {
    return parent;
  }

  for (const subtype of parent.subtypes) {
    const foundSubtype: any = findSubtypeById(subtype, subtypeId);
    if (foundSubtype) {
      return foundSubtype;
    }
  }

  return null;
}

export function createLibraryQueryString(
  filters: FiltersState,
  history: History
) {
  const market: string[] = filters.markets.selectedMarkets || [];
  const typology: string[] = filters.typologies.selectedTypologies || [];
  const search: string | undefined = filters.searchString.text?.trim();
  const subTypology: string | undefined =
    filters.typologies.selectedSubtypology?.id?.trim();

  const queryParams = Object.assign(
    {
      market,
      typology
    },
    typology.length && subTypology !== "" && { subTypology },
    search !== "" && { search }
  );

  return queryString.stringify(queryParams, {
    arrayFormat: "comma"
  });
}